import { defineStore } from 'pinia'

export const useUserPreferencesStore = defineStore('userPreferences', () => {
  const prefersContentInWebsiteLanguageOnly = ref(false)

  const setValueForPrefersWebsiteLanguageOnly = (value: boolean) => {
    prefersContentInWebsiteLanguageOnly.value = value
  }

  return {
    prefersContentInWebsiteLanguageOnly,
    setValueForPrefersWebsiteLanguageOnly,
  }
})
