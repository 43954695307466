import * as prismic from '@prismicio/client'
import consts from '@/consts'
import { useUserPreferencesStore } from '@/store/userPreferences'
/**
 *
 * @param options
 * @returns An array of articles based on the options/params entered.
 */

export default async function (options: UseArticlesOptions) {
  const { client } = usePrismic()
  const route = useRoute()
  const defaultPrismicQueryParams = useDefaultPrismicQueryParams()
  const currentIsoLang = defaultPrismicQueryParams.lang.substring(0, 2) as IsoLang

  // Unique, assuming this function is never used more than once, with the same options, on the same page.
  const uniqueIdForApiCall = getChecksumFromAnyInput(options)

  const {
    data: articles,
    refresh,
    pending,
  } = await useAsyncData(uniqueIdForApiCall, () => {
    let initialPageNumber = 1
    const defaultOptions = {
      pageSize: 10,
      categories: Object.keys(consts.articleUrlPrefixMap) as ValidArticleCategory[],
      shouldLandingPagesBeExcluded: true,
    }
    if (route.query?.page && parseInt(String(route.query?.page)) > 1) {
      // If there are page=x query params, use them.
      initialPageNumber = parseInt(String(route.query?.page))
    }

    const mergedOptions = {
      ...defaultOptions,
      ...options,
      page: initialPageNumber,
    }

    const langsToExcludeFromArticleQuery = useUserPreferencesStore()
      .prefersContentInWebsiteLanguageOnly
      ? getIsoLangsExcludingOne(currentIsoLang)
      : []

    const filters = [
      prismic.filter.not('my.article.hide_in_this_language', 'Yes'),
      prismic.filter.any('my.article.category', mergedOptions.categories),
      ...langsToExcludeFromArticleQuery.map((langToFilterOut) =>
        prismic.filter.not('my.article.article_language', langToFilterOut),
      ),
    ]

    if (mergedOptions.shouldLandingPagesBeExcluded) {
      filters.push(prismic.filter.not('my.article.should_be_available_as_landing_page', true))
    }

    return client.getByType('article', {
      ...defaultPrismicQueryParams,
      ...{
        filters,
        pageSize: mergedOptions.pageSize,
        page: mergedOptions.page,
        orderings: [{ field: 'my.article.date', direction: 'desc' }],
        lang: mergedOptions.lang ?? defaultPrismicQueryParams.lang,
      },
    })
  })

  watch(
    () => [route.query, useUserPreferencesStore().prefersContentInWebsiteLanguageOnly],
    () => {
      refresh()
      scrollToElementWithId(consts.articleListId)
    },
  )

  return { articles, pending }
}
