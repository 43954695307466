<script setup lang="ts">
import { useUserPreferencesStore } from '@/store/userPreferences'

const setValueForPrefersWebsiteLanguageOnly = (value: boolean) => {
  useUserPreferencesStore().setValueForPrefersWebsiteLanguageOnly(value)
}
const prefersContentInWebsiteLanguageOnly = computed(() => {
  return useUserPreferencesStore().prefersContentInWebsiteLanguageOnly
})
</script>

<template>
  <div class="language-toggler">
    <div class="language-toggler__inner">
      <button
        class="language-toggler__button"
        :disabled="prefersContentInWebsiteLanguageOnly ? false : true"
        @click="() => setValueForPrefersWebsiteLanguageOnly(false)"
      >
        {{ $t('news_lang_filter_option_all') }}
      </button>
      <button
        class="language-toggler__button"
        :disabled="!prefersContentInWebsiteLanguageOnly ? false : true"
        @click="() => setValueForPrefersWebsiteLanguageOnly(true)"
      >
        {{ $t('news_lang_filter_option_current_only') }}
      </button>
    </div>
  </div>
</template>

<style lang="less">
@import (reference) '~/assets/less/mixins.less';

.language-toggler {
  &__inner {
    display: inline-flex;
    .fluid(gap, 16, 20);
    background-color: var(--base-2);
    padding: 8px;
    border-radius: 8px;
  }
  &__button {
    .button-reset();
    font-weight: 400;
    transition: 0.2s;
    .fluid(padding-left, 12, 16);
    .fluid(padding-right, 12, 16);
    .fluid(padding-top, 6, 8);
    .fluid(padding-bottom, 6, 8);
    font-size: 14px;
    border-radius: 4px;

    &:hover {
      .supports-hover({
        background-color: rgba(255, 255, 255, 0.5);
      });
    }

    &:disabled {
      cursor: default;
      background-color: #fff;
      box-shadow: 2px 2px 4px 0px rgba(217, 212, 200, 0.42);
    }
  }
}
</style>
