import type { ArticleDocument } from '@/types/prismic.generated'
import consts from '@/consts'
import { i18nPages } from '@/i18n.pages'

export default function (
  article: ArticleDocument,
  customLocaleCode: PrismicLocaleCode | undefined = undefined,
) {
  let localeCode: PrismicLocaleCode = 'en-us'

  if (customLocaleCode) {
    localeCode = customLocaleCode
  } else {
    // We are using this func in serverRoutes and useI18n() does not work there. Therefore, we have to call useI18n() here.
    const { locale } = useI18n()
    localeCode = locale.value as PrismicLocaleCode
  }
  const { uid } = article
  const { category, should_be_available_as_landing_page: shouldBeAvailableAsLandingPage } =
    article.data

  const articleCategory = category !== null ? category : consts.defaultArticleCategory
  const urlPrefix = shouldBeAvailableAsLandingPage
    ? ``
    : `${i18nPages[consts.articleUrlPrefixMap[articleCategory]][localeCode]}`

  const localeCodeUrlPrefix = localeCode === 'en-us' ? '' : `/${localeCode}`

  return `${localeCodeUrlPrefix}${urlPrefix}/${uid}`
}
