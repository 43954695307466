/**
 * Takes in a single ISO language code and returns all others ISO languages codes in an array.
 *
 * @param {IsoLang} excludedIsoLangCode
 * @returns {IsoLang[]}
 */

import type { LocaleObject } from '@nuxtjs/i18n'
import i18nSettings from '@/i18n.settings'

export default (excludedIsoLangCode: IsoLang): IsoLang[] => {
  const availableIsoLangCodes =
    i18nSettings.locales === undefined
      ? []
      : i18nSettings.locales
          .map((locale) => {
            const localeObject = locale as LocaleObject
            return localeObject.iso as IsoLang
          })
          .filter((isoLocale) => isoLocale !== excludedIsoLangCode)
  return availableIsoLangCodes
}
